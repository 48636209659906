.phase {
  max-width: 424px;
  text-align: center;
}

.phase h1 {
  color: var(--Yellow, #fee727);
  font-family: "Londrina Solid";
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.phase p {
  color: var(--Yellow, #fee727);
  font-family: "Londrina Solid";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
