.joinButton {
  padding: 10px;
}
.joinButton svg {
  max-width: 424px;
  width: 100%;
}

.joinButton .joinButton-text {
  font-family: "Londrina Solid";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.joinButton svg:hover {
  cursor: pointer;
}
