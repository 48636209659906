.front {
  padding: 150px 50px 50px 50px;
  background-color: black;
  background-image: url(../../assets/World\ Map.svg);
  position: relative;
}

.front-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.right-leaf {
  position: absolute;
  bottom: 50px;
  right: 0px;
  z-index: 1;
}

.front-left {
  position: relative;
  z-index: 2;
  max-width: 682px;
}

.front-left .front-left-heading h1 {
  width: 100%;
  text-transform: uppercase;
  font-family: Archivo Black;
  font-size: 90px;
  font-weight: 900;
  line-height: 100px;
  letter-spacing: -0.015em;
  text-align: left;
  /* text-align: center; */
  color: #95d701;
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.front-left-heading-top {
  color: #fee727 !important;
}

.front-left-button button {
  width: 230px;
  /* font-weight: 900; */
  border: none;
  /* font-size: larger; */
  /* text-align: center; */
  text-align: left;
  /* padding: 20px; */
  margin-top: 50px;
  background-color: black;
}

.front-left-button .front-left-button-1 {
  margin-right: 75px;
  /* color: #181e21; */
  /* background-color: #92d90d; */
}

.front-left-button .front-left-button-2 {
  margin-right: 75px;
  /* color: #92d90d; */
  /* background-color: #7c6af0; */
}

.front-left .front-left-button {
  width: 100%;
}

.front-left-bottom-text {
  margin-top: 50px;
  font-size: 36px;
  line-height: normal;
  color: #fee727;
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
}

.front-left-bottom-text span {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.front-left-bottom-text .front-left-bottom-text-second {
  color: #fd3eb6;
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.front-right {
  max-width: 700px;
  position: relative;
}

.front-right .front-right-image {
  margin-bottom: 100px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.top-leaf {
  position: absolute;
  top: -100px;
  right: 80px;
  z-index: 0;
}

.left-leaf {
  position: absolute;
  bottom: -30px;
  right: 250px;
  z-index: 3;
}

@media (max-width: 1408px) {
  .front-left-heading h1 {
    text-align: center !important;
  }

  .front-left-button button {
    text-align: center !important;
    margin: 20px !important;
  }

  .front-left-bottom-text {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  /* .front {
    padding: 30px 40px;
  } */

  .front-left .front-left-button {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .front {
    padding: 100px 30px;
  }

  .front-left .front-left-button {
    margin-top: 70px;
    text-align: center;
  }

  .front-left .front-left-heading h1 {
    font-size: 60px;
    line-height: 70px;
  }

  .front-left-button button {
    max-width: 200px;
  }

  .front-left-bottom-text {
    margin: 25px 0;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .top-leaf {
    position: absolute;
    top: -80px;
    right: 50px;
    z-index: 0;
    width: 50%;
  }

  .right-leaf {
    position: absolute;
    bottom: 50px;
    right: 0px;
    z-index: 1;
    width: 30%;
  }

  .left-leaf {
    position: absolute;
    bottom: 0px;
    right: 250px;
    z-index: 3;
    width: 30%;
  }
}

@media (max-width: 300px) {
  .front-left .front-left-heading h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .front-left .front-left-button {
    margin-top: 50px;
    text-align: center;
  }

  .front-left-button button {
    max-width: 150px;
  }

  .front-left-bottom-text {
    margin: 20px 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .top-leaf {
    position: absolute;
    top: -50px;
    right: 30px;
    z-index: 0;
    width: 50%;
  }

  .left-leaf {
    position: absolute;
    bottom: 60px;
    right: 100px;
    z-index: 3;
    width: 40%;
  }
}
