.footerDown {
  background: var(--Black, #0e0e0e);
  padding: 20px;
}

.footerDown-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

.footerDown-text-first {
  color: var(--Green, #95d701);
  text-align: center;
  font-family: "Londrina Solid";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.footerDown-text-second {
  color: var(--Pink, #fd3eb6);
  font-family: "Londrina Solid";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@media only screen and (max-width: 768px) {
  .footerDown-text-first {
    font-size: 25px;
  }

  .footerDown-text-second {
    font-size: 25px;
  }
}

@media only screen and (max-width: 480px) {
  .footerDown-text-first {
    font-size: 20px;
  }

  .footerDown-text-second {
    font-size: 20px;
  }

  .footerDown-text {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .footerDown-text-first {
    font-size: 15px;
  }

  .footerDown-text-second {
    font-size: 15px;
  }

  .footerDown-text {
    padding-bottom: 50px;
  }
}
