.menu {
  display: none;
}

.MuiDrawer-paper {
  background-color: black !important;
  color: white !important;
}

/* .menu a {
  text-decoration: none;
  color: white !important;
} */

@media (max-width: 1375px) {
  .menu {
    display: block;
  }
}

.menu-item {
  text-decoration: none;
  color: white;
}
