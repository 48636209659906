.join {
  padding: 100px 50px 250px 50px;
  background-color: #95d701;
  background-image: url(../../assets/newAssets/Grid.png);
}
.join-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.join-section-left {
  max-width: 518px;
  color: var(--Black, #0e0e0e);
  font-style: normal;
  line-height: normal;
  text-align: center;
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
}

.join-section-left span {
  color: var(--Pink, #fd3eb6);
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  text-align: center;
  font-size: 38px;
}

.join-section-left h2 {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 20px;
  line-height: normal;
  text-align: center;
  font-size: 38px;
}

.join-section-right {
  max-width: 591px;
  background-image: url(../../assets/newAssets/Group\ 3.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  text-align: center;
}

.join-section-right img {
  width: 100%;
}

@media (max-width: 480px) {
  .join-section-left span,
  h2 {
    font-size: 28px !important;
  }
}
