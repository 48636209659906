.tokenomicsButton {
  padding: 20px;
}

.tokenomicsButton svg {
  /* max-width: 536px; */
  /* width: 100%; */
  width: 536px;
  position: relative;
  z-index: 5;
  /* padding: 20px; */
}

.top-text {
  color: var(--Yellow, #fee727);
  text-align: center;
  font-family: "Londrina Solid";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottom-text {
  color: var(--Pink, #fd3eb6);
  font-family: "Londrina Solid";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 720px) {
  .tokenomicsButton svg {
    /* max-width: 536px; */
    width: 100%;
    padding: 10px;
    /* width: 536px; */
  }
}

.tokenomicsButton svg:hover {
  cursor: pointer;
}
