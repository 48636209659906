.heroButton svg {
  width: 100%;
}

.custom-text {
  color: var(--Light-Purple, #562f92);
  font-family: "Londrina Solid";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center !important;
}

.heroButton svg:hover {
  cursor: pointer;
}
