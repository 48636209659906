.profile {
  background-color: black !important;
  color: white;
  border-radius: 10px;
  /* padding: 10px; */
  padding: 30px;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 2px 11px;
}

.profile-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-left-text {
  padding-left: 10px;
}

.profile-right Button {
  background-color: #fd3eb6;
}

@media (max-width: 480px) {
  .profile {
    padding: 10px;
    margin: 30px 0;
    margin-bottom: 10px;
  }
}
