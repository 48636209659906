/* .footerUp {
  background: var(--Yellow, #fee727);
  padding: 10px 50px;
}

.footerUp p {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: center;
  overflow: hidden;
}

.footerUp span {
  color: var(--Pink, #fd3eb6);
  text-align: center;
  font-family: "Londrina Solid";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 10px 30px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .footerUp span {
    font-size: 24px;
    padding: 10px 20px;
  }

  .footerUp {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 480px) {
  .footerUp span {
    font-size: 18px;
    padding: 10px 15px;
  }

  .footerUp {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 320px) {
  .footerUp span {
    font-size: 14px;
    padding: 5px 20px;
  }

  .footerUp {
    padding: 5px 10px;
  }
} */

.main-section .slider-container {
  /* background: var(--Yellow, #fee727); */
  background-color: #fee727 !important;
  padding: 10px 50px;
}

/* .footerUp p {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: center;
  overflow: hidden;
} */

.main-section .footerUp span {
  color: var(--Pink, #fd3eb6);
  text-align: center;
  font-family: "Londrina Solid";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 10px 30px;
  text-align: center;
}

.main-section .footerUp-text span {
  color: #fd3eb6;
}

.main-section .slick-prev {
  display: none !important;
}

.main-section .slick-next {
  display: none !important;
}

/* @media only screen and (max-width: 768px) {
  .footerUp span {
    font-size: 24px;
    padding: 10px 20px;
  }

  .footerUp {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 480px) {
  .footerUp span {
    font-size: 18px;
    padding: 10px 15px;
  }

  .footerUp {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 320px) {
  .footerUp span {
    font-size: 14px;
    padding: 5px 20px;
  }

  .footerUp {
    padding: 5px 10px;
  }
} */
