@import url("https://fonts.googleapis.com/css2?family=Single+Day&display=swap");

.template-section {
  text-align: center;
}

.template-section-heading {
  max-width: 803px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  position: relative;
  z-index: 5;
}

.template-section-heading h1 {
  font-size: 72px;
  line-height: normal;
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.template-section p {
  max-width: 803px;
  margin: auto;
  color: var(--Black, #0e0e0e);
  text-align: center;
  font-size: 36px;
  line-height: normal;
  letter-spacing: -1.8px;
  font-family: "Single Day";
  font-weight: 400;
  font-style: normal;
}

@media (max-width: 768px) {
  .template-section-heading h1 {
    font-size: 42px;
  }
}
