.balance {
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 30px;
  margin: 30px;
  box-shadow: 0px -2px 2px 11px;
}

.balance h1 {
  text-align: center;
}

.balance-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-top: 10px;
}

@media (max-width: 480px) {
  .balance {
    border-radius: 10px;
    padding: 10px;
    /* margin: 0px; */
    /* margin-bottom: 10px; */
    margin: 30px 0;
  }
}
