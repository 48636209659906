.level {
  background-color: black;
  color: white;
  padding: 30px 50px;
  border-radius: 10px;
  margin: 30px;
  box-shadow: 0px -2px 2px 11px;
}

.level h1 {
  text-align: center;
}

.level-card {
  /* max-width: 200px; */
  /* background-color: orangered; */
  background-color: #fd3eb6;
  color: black;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin: 10px 0;
}

.level-card-box {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
}

.level-card-box-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.level-card-box input {
  border-radius: 10px;
}

@media (max-width: 480px) {
  .level {
    padding: 20px 25px;
    /* margin: 0px; */
    /* margin-bottom: 10px; */
    margin: 30px 0;
  }
}
