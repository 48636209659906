.footerMid {
  background: var(--Black, #0e0e0e);
  padding: 50px;
}

.footerMid-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.footerMid-child {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 20px;
  cursor: pointer;
}

.footerMid-child span {
  color: var(--Grey, #535a5b);
  font-family: "Londrina Solid";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
}

@media only screen and (max-width: 768px) {
  .footerMid {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 480px) {
  .footerMid {
    padding: 50px 20px;
  }

  .footerMid-child {
    width: 100%;
    justify-content: flex-start;
    padding: 10px 10px;
  }

  .footerMid-child span {
    font-size: 26px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 320px) {
  .footerMid {
    padding: 50px 10px;
  }

  .footerMid-child {
    width: 100%;
    justify-content: flex-start;
    padding: 10px 10px;
  }

  .footerMid-child span {
    font-size: 20px;
    padding-left: 10px;
  }
}
