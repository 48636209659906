/* .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 150px;
  background-color: black;
  position: relative;
  z-index: 1;
}

.header-prism {
  position: absolute;
  top: 0px;
  left: 25%;
  max-width: 25%;
  width: 100%;
  z-index: 0;
}

.header-yellow-prism {
  position: absolute;
  right: 0px;
  top: 30px;
  z-index: 0;
  max-width: 7%;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
  z-index: 2;
  max-width: 20%;
}

.header-left-logo {
  max-width: 80%;
  width: 100%;
}

.header-right {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

.header-right-home a {
  color: #fd5dc9;
  text-decoration: none;
  font-weight: 800;
  line-height: 40px;
  height: 40px;
  size: 20px;
  font-size: x-large;
}

.header-right-text a {
  text-decoration: none;
  height: 40px;
  size: 20px;
  color: #7c6af0;
  font-size: x-large;
}

.header-right-shop button {
  background-color: #b2e51a;
  width: 164px;
  height: 47px;
  font-weight: 900;
  font-size: large;
  border: none;
  position: relative;
  z-index: 5;
}

@media (max-width: 1200px) {
  .header {
    padding: 30px 50px;
  }

  .header-right {
    gap: 30px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .header-right {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 810px) {
  .header {
    display: block;
    position: fixed;
    z-index: 111;
  }

  .header-left {
    max-width: 80%;
  }

  .header-left-logo {
    max-width: 30%;
    width: 100%;
  }

  .header-right {
    display: none;
  }

  .header-prism {
    position: absolute;
    top: 0px;
    left: 30%;
    max-width: 50%;
  }

  .header-yellow-prism {
    position: absolute;
    right: 0px;
    top: 30px;
    z-index: 0;
    max-width: 10%;
  }
} */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 50px;
  background-color: black;
  /* position: relative; */
  position: fixed;
  width: 100%;
  z-index: 20;
}

.header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  position: relative;
  max-width: 250px;
}

.header-left img {
  width: 100%;
}

.header-right {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
}

.header-right-home a {
  color: #fee727;
  text-decoration: none;
  font-family: "Londrina Solid";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.header-right-text a {
  text-decoration: none;
  color: #95d701;
  font-family: "Londrina Solid";
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.header-right-shop a {
  font-size: large;
  padding-right: 10px;
}

.header-right-shop a svg {
  font-size: xx-large;
  color: #fd3eb6;
}

@media (max-width: 1375px) {
  .header-right {
    display: none;
  }
}

@media (max-width: 1200px) {
  .header {
    padding: 5px 50px;
    position: fixed;
    width: 100%;
    z-index: 20;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 5px 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 20;
  }

  .header-right {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 810px) {
  .header {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 20;
  }

  .header-left {
    max-width: 150px;
  }

  .header-right {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .header {
    padding: 5px 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    z-index: 20;
  }

  .header-left img {
    width: 50%;
  }
}

a:hover {
  cursor: pointer;
}
