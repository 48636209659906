.link {
  background-color: black !important;
  color: white;
  border-radius: 10px;
  /* padding: 10px; */
  padding: 30px;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -2px 2px 11px;
}

.link-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link-left-text {
  padding-left: 10px;
}

.link-left-text h4 {
  max-width: 175px;
  width: 100%;
  overflow: hidden;
}

.link-right Button {
  background-color: #fd3eb6;
}

@media (max-width: 480px) {
  .link {
    padding: 10px;
    /* margin: 0px; */
    /* margin-bottom: 10px; */
    margin: 30px 0;
  }

  .link-left-text h4 {
    max-width: 125px;
    width: 100%;
    overflow: hidden;
  }
}
