.tokenomics {
  padding: 0 50px;
  background: var(--Pink, #fd3eb6);
  background-image: url(../../assets/newAssets/Grid.png);
  position: relative;
}

.tokenomics-section {
  text-align: center;
  margin: auto;
  max-width: 1320px;
  background: var(--Yellow, #fee727);
  position: relative;
  top: -150px;
  padding: 50px 0;
  padding-top: 100px;
}

.tokenomics-section-leaf {
  position: absolute;
  top: -70px;
  right: 0px;
  width: 15%;
  z-index: 1;
}

.tokenomics-section-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

/* .tokenomics-section-bottom img {
  max-width: 536px;
  width: 100%;
  padding: 20px;
  position: relative;
  z-index: 5;
} */

.tokenomics-section-smiley {
  position: absolute;
  left: -100px;
  bottom: -200px;
  z-index: 1;
  /* width: 100%; */
}

@media (max-width: 768px) {
  .tokenomics {
    padding: 50px 20px;
  }

  .tokenomics-section-leaf {
    top: -50px;
    right: 0px;
    width: 25%;
  }

  .tokenomics-section-smiley {
    position: absolute;
    left: -100px;
    bottom: -200px;
    z-index: 1;
    width: 50%;
  }
}
