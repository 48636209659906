.leaderBoard {
  background-color: black !important;
  color: white;
  border-radius: 10px;
  padding: 30px;
  /* padding: 10px; */
  margin: 30px;
  box-shadow: 0px -2px 2px 11px;
}
.leaderBoard h1 {
  text-align: center;
}

.leaderBoard-list {
  padding-top: 20px;
}
.leaderBoard-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.leaderBoard-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.leaderBoard-list-item-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.leaderBoard-bottom Button {
  background-color: #fd3eb6;
}

@media (max-width: 480px) {
  .leaderBoard {
    padding: 10px;
    /* margin: 0px; */
    /* margin-bottom: 10px; */
    margin: 30px 0;
  }

  .leaderBoard h1 {
    font-size: large;
  }

  .leaderBoard-list-item-left {
    gap: 10px;
  }
}
