.roadmap {
  background: var(--Pink, #fd3eb6);
  background-image: url(../../assets/newAssets/Grid.png);
  padding: 100px 50px 50px 50px;
}

.roadmap-phase {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 50px;
}

@media (max-width: 768px) {
  .roadmap {
    padding: 50px 20px 50px 20px;
  }
}
