.meter {
  background-color: black;
  color: white;
  padding: 30px;
  border-radius: 10px;
  margin: 30px;
  box-shadow: 0px -2px 2px 11px;
}

.meter h1 {
  text-align: center;
}

.meter-bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* justify-content: space-between; */
  /* position: absolute; */
  /* bottom: 50px; */
  /* left: 20px; */
}

.meter-bottom-mid {
  text-align: center;
  /* display: flex; */
}

@media (max-width: 480px) {
  .meter {
    padding: 10px;
    /* margin: 0px; */
    /* margin-bottom: 10px; */
    margin: 30px 0;
  }
}
