.dashboard-section {
  padding: 150px 50px 100px 50px;
  background-color: black;
  background-image: url(../../assets/World\ Map.svg);
}

.dashboard-section-heading {
  text-align: center;
  color: #fd3eb6;
  font-weight: 900;
  text-transform: uppercase;
  font-size: xxx-large;
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  /* gap: 20px; */
}

.dashboard-left {
  /* max-width: 33%; */
  max-width: 440px;
  width: 100%;
}

.dashboard-mid {
  /* max-width: 33%; */
  max-width: 440px;
  width: 100%;
}

.dashboard-right {
  /* max-width: 33%; */
  max-width: 440px;
  width: 100%;
}

/* @media (max-width: 1200px) {
  .dashboard-left {
    max-width: 440px;
    width: 100%;
  }

  .dashboard-mid {
    max-width: 440px;
    width: 100%;
  }

  .dashboard-right {
    max-width: 440px;
    width: 100%;
  }
} */

@media (max-width: 480px) {
  .dashboard-section {
    padding: 100px 20px 50px 20px;
  }

  .dashboard-section-heading {
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    font-size: xx-large;
  }
}
